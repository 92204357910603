* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --color-bg-dark: #192a33;
  --color-bg-light: #1f3640;
  --color-gray: #a8bee9;
  --color-gray-dark: #7a8991;
  --color-yellow: #f2b238;
  --color-yellow-dark: #cd8d16;
  --color-blue: #30c4be;
  --color-blue-dark: #128c89;

  --shadow-y: 6px;
}

html {
  font-size: 16px;
  text-transform: uppercase;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  background-color: var(--color-bg-dark);
  color: var(--color-gray);
}

.App {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 450px;
  width: 100%;
  margin-inline: auto;
  padding: 2rem 1rem;
}

/* ========================== Start Utilities ========================== */
.card {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px; 
  border-radius: 15px;
  background-color: var(--color-bg-light);
  text-align: center;
}

.text-lg {
  font-size: 1.25rem;
}

.text-xl {
  font-size: 1.5rem;
}

.text-2xl {
  font-size: 1.725rem;
}

.text-light {
  color: var(--color-gray-dark);
}

.text-blue {
  color: var(--color-blue);
}

.text-yellow {
  color: var(--color-yellow);
}

.btn {
  background: var(--color-gray);
  border: none;
  color: var(--color-gray-dark);
  font-weight: bold;
  padding: 12px 25px;
  font-size: 1.125rem;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 var(--shadow-y) 0 var(--color-gray-dark);
  margin-bottom: 6px;
  transition: all 0.2s ;
}

.btn:hover {
  --shadow-y: 8px;
  transform: translateY(-2px);

}

.btn:active {
  transform: translateY(2px);
  --shadow-y:4px;
}

.btn-yellow {
  background: var(--color-yellow);
  color: var(--color-bg-dark);
  box-shadow: 0 var(--shadow-y) 0 var(--color-yellow-dark);
}

.btn-blue {
  background: var(--color-blue);
  color: var(--color-bg-dark);
  box-shadow: 0 var(--shadow-y) 0 var(--color-blue-dark);
}

.btn-sm {
  --shadow-y: 4px;
}

.btn-sm:hover {
  --shadow-y: 6px;
}

.btn-sm:active {
  --shadow-y: 3px;
}
/* ========================== End Utilities ========================== */

/* ========================== Start Icons ========================== */
.icon {
  display: inline-block;
}

.icon svg {
  fill: var(--color-gray);
  height: 50px;
  width: 50px;
  vertical-align: middle;
}

.icon-lg svg {
  height: 65px;
  width: 65px;
}

.icon-sm svg {
  height: 30px;
  width: 30px;
}

.icon-yellow svg {
  fill: var(--color-yellow);
}

.icon-blue svg {
  fill: var(--color-blue);
}

.icon-dark svg {
  fill: var(--color-bg-dark);
}

/* ========================== End Icons ========================== */

/* ========================== Start ========================== */

.start {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.start__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start__players {
  background-color: var(--color-bg-dark);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
}

.start__players span {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.start__players--active {
  background-color: var(--color-gray);
  color: var(--color-bg-dark);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.2);
}

.start__btns {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* ========================== End Start ========================== */
   
/* ========================== Start Game ========================== */

.board {
}
.board__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.board__turn {
  border-radius: 5px;
  background-color: var(--color-bg-light);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.board__restart svg {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  transition: all 0.3s;
}

.board__restart:active svg {
  transform: rotate(180deg);
}

.board__body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
}
.board__body .card {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-inline: 10px;
}

.board__body .active.shadow-blue {
  background-color: var(--color-blue);
}

.board__body .active.shadow-yellow {
  background-color: var(--color-yellow);
}

.board__footer {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.board__footer .card {
  gap: 15px;
}

/* ========================== End Game ========================== */

/* ========================== Start modal ==========================  */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}
.modal.closed {
  display: none;
}
.modal__content {
  width: 100%;
  background-color: var(--color-bg-light);
}

/* ========================== End modal ==========================  */

/* ========================== Start score ========================== */
.score {
  text-align: center;
}
.score__title {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 30px 0;
  justify-content: center;
}

.score__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* ========================== End score ========================== */

/* ========================== Start restart ==========================  */
.restart {
  text-align: center;
}
.restart__title {
  font-size: 2rem;

  margin-bottom: 30px;
}

.restart__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* ========================== End restart ==========================  */